import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import cn from 'classnames';
import { APP_VERSION } from 'config/version';
import { useDispatch } from 'modules/common/helpers/redux/useActions';
import { useUserTheme } from 'modules/common/hooks/useUserTheme';
import { authActions } from 'modules/common/redux/auth';
import { useAuth } from 'modules/common/selectors/auth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useRouter from 'use-react-router';
import AuthWrapper from '../../../containers/AuthWrapper';
import { useStyles } from './HeaderStyles';

interface HeaderProps {
  isOpen: boolean;
  onOpen: () => void;
  hideMenu?: boolean;
}

const Header: React.SFC<HeaderProps> = ({ isOpen, onOpen, hideMenu }) => {
  const classes = useStyles({});
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const userLabel = user ? user.username : '';
  const {history} = useRouter();
  const { userTheme } = useUserTheme(user && user.username);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleProfileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleLogout() {
    handleMenuClose();
    dispatch(authActions.logout.request());
  }

  function handleMobileMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function handleProfile(){
    if(user){
      history.push(`/user/${user.uuid}/info`);
    }
    handleMenuClose();
  }

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleProfile}>{t('auth.profile')}</MenuItem>
      <MenuItem onClick={handleLogout}>{t('auth.logout')}</MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <Tooltip title={userLabel}>
          <IconButton>
            <AccountCircle />
          </IconButton>
        </Tooltip>
        <p>Konto</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={cn(classes.grow, classes.root, { [classes.shifted]: isOpen })}>
      <AppBar position="static" style={{maxHeight: 54, minHeight: 54}} elevation={1} >
        <Toolbar style={{maxHeight: 54, minHeight: 54, background: '#fff'}} >
          <AuthWrapper>
            {
              !hideMenu && !isOpen && (
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onOpen}
                >
                  <MenuIcon style={{fill: '#000'}}/>
                </IconButton>
              )
            }
          </AuthWrapper>
          {!isOpen && <img src={userTheme.logo} className={classes.logo} />}
          <label style={{display: 'none', opacity: 0.7, fontSize: '0.7em'}}>{APP_VERSION}</label>
          <div className={classes.grow} />
          <div className={classes.tabs}>
            <Tabs value={0}></Tabs>
          </div>
          <AuthWrapper>
            <div className={classes.sectionDesktop}>
              {/* <NotificationsIcon /> */}
              <Tooltip title={userLabel}>
                <IconButton
                  edge="end"
                  aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="default"
                >
                  <AccountCircle fill='#000'/>
                </IconButton>
              </Tooltip>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton aria-haspopup="true" onClick={handleMobileMenuOpen}>
                <MoreIcon />
              </IconButton>
            </div>
          </AuthWrapper>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {renderMobileMenu}
    </div>
  );
};

export default Header;
