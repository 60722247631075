import * as React from "react";
import { useTranslation } from 'react-i18next';
import styles from './LoginBox.module.scss';
import Snackbar from "@material-ui/core/Snackbar";

interface LoginBoxMessagesProps {
    fetching: boolean;
    successMessage: string;
    errorMessage: string;
}
const LoginBoxMessages: React.SFC<LoginBoxMessagesProps> = ({ fetching, errorMessage, successMessage }) => {
    const { t } = useTranslation();

    return (
        <div className={styles.messagesContainer}>
            <Snackbar
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                open={!fetching && errorMessage.length > 0}
                className={styles.error}
                message={t(errorMessage)}
            />
            <Snackbar
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                open={!fetching && successMessage.length > 0}
                className={styles.success}
                message={t(successMessage)}
            />
        </div>
    );
};

export default LoginBoxMessages;