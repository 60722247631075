import { createMuiTheme } from '@material-ui/core/styles';
import styles from './../../../styles/colors.module.scss';

export const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        background: styles.headerBackground + ' !important',
        color: '#ffffff'
      }
    },
    MuiMenu: {
      paper: {
        //   color: '#ff0000'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0
      }
    },
    MuiTextField: {
      root: {
        borderRadius: 0
      }
    },
    MuiInput: {
      root: {
        borderRadius: 0
      }
    },
    MuiTab: {
      wrapper: {
        flexDirection: 'row',
      },
    },
  },
  palette: {
    primary: { main: styles.primaryColor },
    secondary: { main: styles.secondaryColor }, // This is just green.A700 as hex.
  },
});

export const themeDTS = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        background: styles.headerBackground + ' !important',
        color: '#ffffff'
      }
    },
    MuiMenu: {
      paper: {
        //   color: '#ff0000'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0
      }
    },
    MuiTextField: {
      root: {
        borderRadius: 0
      }
    },
    MuiInput: {
      root: {
        borderRadius: 0
      }
    },
  },
  palette: {
    primary: { main: styles.primaryColorDTS },
    secondary: { main: styles.secondaryColorDTS }, // This is just green.A700 as hex.
  },
});

export const themeELP = createMuiTheme({
  overrides: {
    MuiAppBar: {
      root: {
        background: styles.headerBackground + ' !important',
        color: '#ffffff'
      }
    },
    MuiMenu: {
      paper: {
        //   color: '#ff0000'
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0
      }
    },
    MuiTextField: {
      root: {
        borderRadius: 0
      }
    },
    MuiInput: {
      root: {
        borderRadius: 0
      }
    },
  },
  palette: {
    primary: { main: styles.primaryColorELP },
    secondary: { main: styles.secondaryColorELP }, // This is just green.A700 as hex.
  },
});
