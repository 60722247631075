import { FeatureSettings, getFeatureAllowedUserTypes } from "features/Features";
import Auth from "modules/common/components/Auth/Auth";
import * as React from "react";


interface FeatureContainerProps {
    settings: FeatureSettings;
};

const FeatureContainer: React.FC<FeatureContainerProps> = ({ settings, children }) => {
    const types = getFeatureAllowedUserTypes(settings);

    return (
        <Auth type={types}>
            {children}
        </Auth>
    );
};

export default FeatureContainer;