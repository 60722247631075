import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Theme } from '@material-ui/core/styles';
import { Settings } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
import cn from 'classnames';
import TooltipWrapper from 'modules/common/components/Layout/ASide/TooltipWrapper';
import { useAuth } from 'modules/common/selectors/auth';
import * as React from 'react';
import useRouter from 'use-react-router';
import styles from './ASideMenuItem.module.scss';

interface ASideMenuItemProps {
  isActive?: boolean;
  Icon?: React.ComponentType<any>;
  title: string;
  disabled?: boolean;
  premiumMarked?: boolean;
  route?: string | undefined;
  onClick?: any;
  badge?: string;
  isOpen?: boolean;
  index: number;
  devOnly?: boolean;
  className?: string;
}

const StyledBadge = withStyles((theme: Theme) => ({
  badge: {
    top: '50%',
    right: -3,
    // The border color match the background color.
    border: `2px solid ${
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]
      }`,
    background: theme.palette.error.main
  },
}))(Badge);

const StyledBadgeCustom = withStyles((theme: Theme) => ({
  badge: {
    top: '50%',
    right: -3,
    // The border color match the background color.
    border: `2px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]}`,
    background: theme.palette.primary.main,
    color: '#ffffff'
  },
}))(Badge);

const ASideMenuItem: React.SFC<ASideMenuItemProps> = ({
  isActive = false,
  disabled = false,
  Icon = Settings,
  title,
  premiumMarked = false,
  route,
  onClick,
  badge,
  isOpen,
  index,
  devOnly,                                                  // ???
  className
}) => {
  const { history } = useRouter();
  const {user} = useAuth();                                 // ???

  const clickHandler = (evt: React.MouseEvent) => {
    if (onClick) {
      onClick();
    } else {
      route && history.push(`/${route}`)
    }
  }


  const classNames = cn({
    [styles.active]: isActive
  });

  return <ListItem button key={title} disabled={isActive || disabled} className={classNames} onClick={clickHandler}>
    <ListItemIcon>
      <TooltipWrapper title={title} active={isOpen || false} index={index}>
        <div>
        {
          premiumMarked
            ? <StyledBadge badgeContent={'$'} color="primary" className={className}>
                <Icon />
              </StyledBadge>
            : badge
              ? <StyledBadgeCustom className={className} badgeContent={badge}><Icon /></StyledBadgeCustom>
              : <Icon className={className}/>
        }
        </div>
      </TooltipWrapper>
    </ListItemIcon>
    <ListItemText primary={title} />
  </ListItem>;
};

export default ASideMenuItem;
