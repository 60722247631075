let config = {
    URL: `/api`,            // API URL
}

export function getConfigURLServer(): string {
    const url = `${process.env.REACT_APP_API_HOST}/api`;
    return url;
}

if (process.env.REACT_APP_LOCAL_ENV === "true") {
    config.URL = `http://127.0.0.1:8080/api`
} else {
    config.URL = getConfigURLServer();
}

if (process.env.NODE_ENV !== 'production') {
    console.log(`@@@ API CONFIG @@@`, config);
}

export default config;