import { DEV, USER, ADMIN } from "constants/user";

export type FeatureSettings = {
    dev?: boolean;
    user?: boolean;
    admin?: boolean;
}

export const getFeatureAllowedUserTypes = (settings: FeatureSettings): UserType[] => {
    let types: UserType[] = [];

    settings.dev && types.push(DEV);
    settings.user && types.push(USER);
    settings.admin && types.push(ADMIN);

    return types;
}