import LinearProgress from '@material-ui/core/LinearProgress';
import { Theme } from '@material-ui/core/styles';
import { createStyles, makeStyles } from '@material-ui/styles';
import cn from 'classnames';
import { Modules } from 'constants/modules';
import UINotificationsContainer from 'modules/common/components/UINotifications/UINotificationsContainer';
import { useApp } from 'modules/common/selectors/app';
import { useAuth } from 'modules/common/selectors/auth';
import * as React from 'react';
import { useState } from 'react';
import useRouter from 'use-react-router';
import ASide from './ASide/ASide';
import Header from './Header/Header';
import styles from './Layout.module.scss';

interface LayoutProps { }

const useStyles = makeStyles((theme: Theme) => createStyles({
    content: {
        paddingLeft: 0,
        [theme.breakpoints.up('sm')]: {
            paddingLeft: 60
        },
    }
}));

const Layout: React.SFC<LayoutProps> = ({ children }) => {
    const { fetched, user } = useAuth();
    const { processing, currentModule } = useApp();
    const {location: {pathname}} = useRouter();
    const [open, setOpen] = useState(false);
    const classes = useStyles({});

    const isAuthorized = fetched && Boolean(user);
    const isPasswordExpiredPage = pathname.includes('password-expired');
    const showHeader = isAuthorized;
    const showAside = isAuthorized && !isPasswordExpiredPage;

    return (
        <div className={styles.root}>
            <div className={styles.progress}>
                {processing && <LinearProgress color='secondary' variant='query' />}
            </div>
            {showHeader && <Header isOpen={open} onOpen={() => setOpen(true)} hideMenu={isPasswordExpiredPage}/>}
            {showAside && <ASide isOpen={open} onClose={() => setOpen(false)} />}
            <div className={cn(
                styles.pageContentWrapper,
                classes.content,
                {
                    [styles.unauthorized]: !isAuthorized,
                    [styles.withoutHeader]: !showHeader
                }
            )}>
                {children}
            </div>
            <UINotificationsContainer />
        </div>
    );
};

export default Layout;
