import { FeatureSettings } from "features/Features";

// 
// Version 1.1
// 

// Map module
export const FEATURE_MAP_MODULE: FeatureSettings = {
    dev: true,
    user: true,
    admin: true,
};

// E-mail notifications
export const FEATURE_EMAIL_NOTIFICATIONS: FeatureSettings = {
    dev: true,
    user: true,
    admin: true,
};

// Virtual HMI
export const FEATURE_VIRTUAL_HMI: FeatureSettings = {
    dev: true,
    user: true,
    admin: true,
}