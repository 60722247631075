import { Button, FormControl, TextField } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import Typography from '@material-ui/core/Typography';
import cn from 'classnames';
import { useLogin } from 'modules/auth/components/LoginBox/LoginBoxHooks';
import LoginBoxMessages from 'modules/auth/components/LoginBox/LoginBoxMessages';
import Loader from 'modules/common/components/Loaders/Loader';
import * as React from "react";
import { useTranslation } from 'react-i18next';
import styles from './LoginBox.module.scss';
import dts from 'images/dts.png';
import elpiast from 'images/elpiast.png';

interface LoginBoxProps { }

const LoginBox: React.SFC<LoginBoxProps> = () => {
    const {
        username,
        password,
        setUsername,
        setPassword,
        isValid,
        error,
        successMessage = "",
        errorMessage = "",
        submitHandler,
        fetching,
        redirecting,
        loggedUsername,
        verified,
        veryfing
    } = useLogin();

    const { t } = useTranslation();

    return (
        verified
            ? null
            : veryfing
                ? <Loader />
                : <>
                <img src="https://dtsserwis.pl/wp-content/uploads/2022/10/foot-build-bg2a.png" style={{opacity: 0.1, position: 'fixed', bottom: 0, left: '50%', transform: `translateX(-50%)`}} />
                    <section className={styles.container}>
                        <img src={dts} className={styles.logo} />
                        <Typography variant="caption" className={styles.label}>
                            {t('auth.login_into_system')}
                        </Typography>
                        <div className={cn(styles.formWrapper, { [styles.redirecting]: redirecting })}>
                            <form onSubmit={submitHandler}>
                                <FormGroup className={styles.form}>
                                    <FormControl className={styles.input}>
                                        <TextField
                                            id="standard-textarea"
                                            label={t('auth.mail')}
                                            placeholder={t('auth.mail')}
                                            className={styles.input}
                                            margin="normal"
                                            defaultValue={username}
                                            onChange={evt => setUsername(evt.currentTarget.value)}
                                            autoFocus
                                            required
                                            variant='outlined'
                                            error={error}
                                            autoComplete="off"
                                        />
                                    </FormControl>
                                    <FormControl className={styles.input}>
                                        <TextField
                                            id="standard-textarea"
                                            label={t('auth.password')}
                                            placeholder={t('auth.password')}
                                            className={styles.input}
                                            margin="normal"
                                            variant='outlined'
                                            defaultValue={password}
                                            onChange={evt => setPassword(evt.currentTarget.value)}
                                            type="password"
                                            required
                                            error={error}
                                        />
                                    </FormControl>
                                    <Button type='submit' className={styles.submitButton} color='primary' variant='contained' disabled={!isValid || fetching} size='large'>
                                        {
                                            fetching
                                                ? t('auth.veryfing')
                                                : t('auth.login')
                                        }
                                    </Button>
                                </FormGroup>
                            </form>
                            <div className={styles.redirectingInfo}>
                                <label>Witaj, <strong>{loggedUsername}</strong></label>
                            </div>
                        </div>
                    </section>
                    <LoginBoxMessages {...{ fetching, successMessage, errorMessage }} />
                </>
    );
};

export default LoginBox;