import { FEATURE_MAP_MODULE } from 'config/features';
import { pages } from 'config/pages';
import FeatureContainer from 'features/FeatureContainer';
import AuthApp from 'modules/auth/AuthApp';
import Loader from 'modules/common/components/Loaders/Loader';
import createRootStore from 'modules/common/createRootStore';
import React, { lazy, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AsyncRoute } from './AsyncComponent';
import AppContainer from './modules/common/containers/AppContainer';
import './styles/common.scss';
const AsyncDeviceApp = lazy(() => import('modules/device/DeviceApp'));
const AsyncDevicesApp = lazy(() => import('modules/devices/DevicesApp'));
const AsyncBuildingsApp = lazy(() => import('modules/buildings/BuildingsApp'));
const AsyncBuildingApp = lazy(() => import('modules/building/BuildingApp'));
const AsyncDataApp = lazy(() => import('modules/data/DataApp'));
// const AsyncConsumptionApp = lazy(() => import('modules/consumption/ConsumptionApp'));
const AsyncViewsApp = lazy(() => import('modules/views/ViewsApp'));
const AsyncViewEditorApp = lazy(() => import('modules/ViewEditor/ViewEditorApp'));
const AsyncEventsApp = lazy(() => import('modules/events/EventsApp'));
const AsyncEventsAppV2 = lazy(() => import('modules/eventsv2/EventsAppV2'));
const AsyncSettingsApp = lazy(() => import('modules/settings/SettingsApp'));
const AsyncUsersApp = lazy(() => import('modules/users/UsersApp'));
const AsyncUserApp = lazy(() => import('modules/user/UserApp'));
const AsyncMapApp = lazy(() => import('modules/map/MapApp'));

const store = createRootStore();

const App: React.SFC<{}> = () => {
  const { t } = useTranslation();

  const message = false
    ? t('pages.messages.veryfing')
    : t('pages.messages.loading_page');

  return (
    <BrowserRouter>
      <Provider store={store}>
        <AppContainer>
          <Suspense fallback={<Loader label={message} />}>
            <Switch>
              <Redirect from='/' exact to={`/buildings`} />
              <Route path='/login' component={AuthApp} />
              <AsyncRoute page={pages.buildings} path='/buildings' loader={AsyncBuildingsApp} />
              <AsyncRoute page={pages.building} path='/building/:uuid' loader={AsyncBuildingApp} />
              <AsyncRoute page={pages.data} path='/data' loader={AsyncDataApp} />
              <AsyncRoute page={pages.viewEditor} path='/viewEditor/:uuid/:buildingUUID?' loader={AsyncViewEditorApp} />
              <AsyncRoute page={pages.devices} path='/devices' loader={AsyncDevicesApp} />
              <AsyncRoute page={pages.device} path='/device/:uuid' loader={AsyncDeviceApp} />
              <AsyncRoute page={pages.settings} path='/settings' loader={AsyncSettingsApp} />
              {/* <AsyncRoute page={pages.events} path='/events' loader={AsyncEventsApp} /> */}
              <AsyncRoute page={pages.eventsv2} path='/events' loader={AsyncEventsAppV2} />
              <AsyncRoute page={pages.users} path='/users' loader={AsyncUsersApp} />
              <AsyncRoute page={pages.user} path='/user/:uuid/:tab?' loader={AsyncUserApp} />
              <AsyncRoute page={pages.views} path='/views' loader={AsyncViewsApp} />
              {/* <AsyncRoute page={pages.consumption} path='/consumption/:uuid?' loader={AsyncConsumptionApp} /> */}
              <FeatureContainer settings={FEATURE_MAP_MODULE}>
                <AsyncRoute page={pages.map} path='/map' loader={AsyncMapApp} />
              </FeatureContainer>
            </Switch>
          </Suspense>
        </AppContainer>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
