import { WidgetType } from "typings/widgets";

export const customViewData: ViewType = {
    uuid: 'hfds38dhiosa',
    name: 'Widok 1',
    subtitle: '',
    type: 'custom',
    config: {
        widgets: [
            {
                uuid: 'x2sdds23ds',
                type: WidgetType.POINTS_LIST,
                name: 'Nastawy',
                config: {
                    points: [
                        'ccc',
                        'xxx-bbbb-cccc-eee2',
                        'yyy-bbbb-cccc-eee3',
                    ]
                }
            },
            {
                uuid: 'fsdds2x3ds',
                type: WidgetType.EVENTS_LIST,
                name: 'Wszystkie alarmy',
                config: {
                    points: [
                        'a-bbbb-cccc-eee1',
                        'a-bbbb-cccc-eee2',
                        'a-bbbb-cccc-eee3',
                        'a-bbbb-cccc-eee4'
                    ]
                }
            },
            {
                uuid: 'x2sdds23ds',
                type: WidgetType.POINTS_LIST,
                name: 'Nastawy',
                config: {
                    points: [
                        'ccc',
                        'xxx-bbbb-cccc-eee2',
                        'yyy-bbbb-cccc-eee3',
                    ]
                }
            },
            {
                uuid: 'x2sdds23ds',
                type: WidgetType.POINTS_LIST,
                name: 'Temperatury',
                config: {
                    points: [
                        'ccc',
                        'xxx-bbbb-cccc-eee2',
                        'yyy-bbbb-cccc-eee3',
                    ]
                }
            },
            {
                uuid: 'x2sdds23ds',
                type: WidgetType.POINTS_LIST,
                name: 'Klimatyzatory',
                config: {
                    points: [
                        'ccc',
                        'xxx-bbbb-cccc-eee2',
                        'yyy-bbbb-cccc-eee3',
                    ]
                }
            },
        ]
    }
};