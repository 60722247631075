import { DEMO_USERNAMES } from 'config/users';
import { useAuth } from 'modules/common/selectors/auth';

export const verifyDemoAccount = (user: UserAuth): boolean => {
  const isDemoAccount = user && DEMO_USERNAMES.includes(user.username);
  return isDemoAccount;
}

export const useDemoAccount = () => {
  const authState = useAuth();
  const isDemoAccount = Boolean(authState.fetched && authState.user && verifyDemoAccount(authState.user));
  return {
    isDemoAccount
  };
}