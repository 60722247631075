// import { createStore, combineReducers, ReducersMapObject, applyMiddleware } from 'redux';
import { applyMiddleware } from "redux";
import { createStore } from "redux-dynamic-modules";
import { getThunkExtension } from 'redux-dynamic-modules-thunk';
import thunk from 'redux-thunk';
import { getRootModule } from './redux';
import { authActions } from "modules/common/redux/auth";
import { getSessionStorageUser } from "modules/common/auth/storage";
import { appActions } from "modules/common/redux/app";


const ENABLE_LOGGING_IN_PRODUCTION_MODE = false;

const createRootStore = () => {
  // const composeEnhancers = typeof window === 'object' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

  const middlewares = [thunk];


  /**
   * Enable redux logger only in dev mode
   */
  if (process.env.NODE_ENV === `development` || ENABLE_LOGGING_IN_PRODUCTION_MODE) {
    const { logger } = require(`redux-logger`);
    middlewares.push(logger);
  }

  const store = createStore({}, [applyMiddleware(...middlewares)], [getThunkExtension()], getRootModule());
  
  if (getSessionStorageUser()) {
    store.dispatch((authActions.verify.request() as any));
  } else {
    if (!window.location.href.includes('/login')){
      store.dispatch((authActions.logout.request() as any));
    }
  }

  return store;
};

export default createRootStore;
