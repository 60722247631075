import { Page } from 'config/pages';
import Loader from 'modules/common/components/Loaders/Loader';
import { useDispatch } from 'modules/common/helpers/redux/useActions';
import { appActions } from 'modules/common/redux/app';
import { useAuth } from 'modules/common/selectors/auth';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route } from 'react-router-dom';
import useRouter from 'use-react-router';

interface AsyncComponentProps {
    fallback?: any;
    props?: any;
    loader: any;
    path: any;
};


export const AsyncComponent: React.SFC<AsyncComponentProps> = ({ fallback = null, props = {}, loader, path }) => {
    const {t} = useTranslation();
    const Lazy = loader;
    return <Lazy {...props} />
}
export default AsyncComponent;


interface AsyncRouteProps {
    path: string;
    page?: Page;
    exact?: boolean;
    props?: any;
    loader: any;
    fallback?: any;
    shouldLoad?: (() => boolean) | undefined;
    premiumOnly?: boolean;
}


interface AuthProps extends Pick<AsyncRouteProps, 'page' | 'path'> {

}

const useAuthRoute = ({ page, path }: AuthProps) => {
    const { user, verified, veryfing, error, fetched } = useAuth();
    const dispatch = useDispatch();
    const isLogged = user !== null;
    const isLoginRoutePath = path === '/login';

    const pageRequireLogin = (page && page.loginRequired) || process.env.REACT_APP_REQUIRE_LOGIN_EVERYWHERE;
    const loginRequired = (pageRequireLogin && !isLogged) && !isLoginRoutePath;

    useEffect(() => {
        page && dispatch(appActions.setModule(page.moduleType));
    }, [page]);

    return {
        loginRequired,
        isLoginRoutePath,
        isLogged,
        verified,
        veryfing,
        fetched,
        error
    }
}

export const AsyncRoute: React.SFC<AsyncRouteProps> = ({ path, page, exact, loader, fallback = null, props = {}, shouldLoad: condition, premiumOnly }) => {
    const { loginRequired, verified, veryfing, fetched, error } = useAuthRoute({ page, path });
    const {location: {pathname, search}} = useRouter();

    // const componentShouldLoad: boolean = Boolean(condition === undefined || condition());
    const freeUserRequirePremiumRoute = premiumOnly;
    const nextAuthorizedRoute = <Route {...{ path, exact }} render={() => <AsyncComponent {...{ loader, props, fallback, path }} />} />;
    const backURI = encodeURIComponent(`${pathname}${search}`);

    return (
        <>
        {
            veryfing
                ? <Loader />
                // User is verified or fetched (after successfull login)
                : verified || fetched
                    ? nextAuthorizedRoute
                    : <Redirect from={path} exact={exact} to={`/login?back=${backURI}`}/>
        }
        </>
    );
};

