import { SvgIconProps } from '@material-ui/core/SvgIcon';
import { AccountCircleOutlined, Assignment, DataUsage, DevicesOtherSharp, EditTwoTone, Home, Map, NotificationImportantOutlined, ScheduleOutlined, ViewModuleOutlined } from '@material-ui/icons';
import { FEATURE_MAP_MODULE } from 'config/features';
import { getFeatureAllowedUserTypes } from 'features/Features';
import { Modules } from '../constants/modules';
import { viewsReducers } from '../modules/views/redux';


export interface Page {
    moduleType: Modules;
    title: string;
    icon: React.ComponentType<SvgIconProps>;
    premiumOnly?: boolean;                      // only for premium
    route?: string;                             // URL route
    reducers?: any;                             // reducers array
    loginRequired?: boolean;                    // require user login to display
    isMainPage?: boolean;                       // redirect to this page after successful login
    ignoreInMenu?: boolean;                     // do not display in menu
    menuRelation?: string;                      // name of related menu module to keep it active
    disabled?: boolean;                         // disabled
    devOnly?: boolean;
    allowedUsers?: UserType[];                     // allowedUsers
}

export interface Pages {
    [page: string]: Page;
}

export const pages: Pages = {
    'map': {
        moduleType: Modules.MAP,
        title: 'Mapa budynków',
        icon: Map,
        route: 'map',
        allowedUsers: getFeatureAllowedUserTypes(FEATURE_MAP_MODULE)
    },
    'buildings': {
        moduleType: Modules.BUILDING,
        title: 'pages.buildings',
        icon: Home,
        route: 'buildings',
        isMainPage: true
    },
    'building': {
        moduleType: Modules.BUILDING,
        title: 'pages.building',
        icon: Home,
        route: 'building',
        ignoreInMenu: true,
        menuRelation: 'buildings'
    },
    'views': {
        moduleType: Modules.VIEWS,
        title: 'pages.views',
        icon: ViewModuleOutlined,
        route: 'views',
        reducers: viewsReducers,
        ignoreInMenu: true
    },
    'viewEditor': {
        moduleType: Modules.VIEW_EDITOR,
        title: 'pages.view_editor',
        icon: EditTwoTone,
        route: 'viewEditor',
        reducers: viewsReducers,
        ignoreInMenu: true
    },
    'data': {
        moduleType: Modules.DATA,
        title: 'pages.data',
        icon: DataUsage,
        route: 'data',
    },
    // 'consumption': {
    //     moduleType: Modules.DATA,
    //     title: 'pages.consumption',
    //     icon: NetworkCheck,
    //     route: 'consumption',
    // },
    'devices': {
        moduleType: Modules.DEVICES,
        title: 'pages.devices',
        icon: DevicesOtherSharp,
        route: 'devices',
        devOnly: true
    },
    'device': {
        moduleType: Modules.DEVICE,
        title: 'pages.device',
        icon: DevicesOtherSharp,
        route: 'device',
        ignoreInMenu: true,
        menuRelation: 'devices'
    },
    'events': {
        moduleType: Modules.EVENTSV2,
        title: 'pages.events',
        icon: NotificationImportantOutlined,
        route: 'events'
    },
    'scheduler': {
        moduleType: Modules.SCHEDULER,
        title: 'pages.calendar',
        icon: ScheduleOutlined,
        route: 'calendar',
        disabled: true,
        ignoreInMenu: true
    },
    'reports': {
        moduleType: Modules.REPORTS,
        title: 'pages.reports',
        icon: Assignment,
        premiumOnly: true,
        route: 'reports',
        disabled: true,
        ignoreInMenu: true
    },
    'users': {
        moduleType: Modules.USERS,
        title: 'pages.users',
        icon: AccountCircleOutlined,
        route: 'users',
    },
    // 'user': {
    //     moduleType: Modules.USERS,
    //     title: 'pages.users',
    //     icon: AccountCircleOutlined,
    //     route: 'user',
    //     ignoreInMenu: true,
    //     menuRelation: 'users'
    // },
    // 'settings': {
    //     moduleType: Modules.SETTINGS,
    //     title: 'pages.settings',
    //     icon: Settings,
    //     route: 'settings'
    // },
    // 'help': {
    //     moduleType: Modules.HELP,
    //     title: 'pages.help',
    //     icon: HelpOutline,
    //     route: 'help',
    //     disabled: true,
    //     ignoreInMenu: true    
    // },
    // "mail": {
    //     moduleType: Modules.MAIL,
    //     title: 'pages.mails',
    //     icon: Mail,
    //     route: 'mails',
    //     disabled: true,
    // }
};

