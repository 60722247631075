// User types
export const NONE: UserType = 'NONE';
export const DEMO: UserType = 'DEMO';
export const SHARED_USER: UserType = 'SHARED_USER';
export const USER: UserType = 'USER';
export const ADMIN: UserType = 'ADMIN';
export const SUPER_ADMIN: UserType = 'SUPER_ADMIN';
export const DEV: UserType = 'DEV';

export const LOWER_PERMISSIONS_USER = USER;

// Account types
export const FREE: AccountType = 'FREE';
export const FULL: AccountType = 'FULL';