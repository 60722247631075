import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import cn from 'classnames';
import { useIntervalEventsCountRequests } from 'modules/common/components/Layout/ASide/asideHooks';
import * as React from 'react';
import AuthWrapper from '../../../containers/AuthWrapper';
import ASideMenu from './ASideMenu';
import { useStyles } from './ASideStyles';

interface MaterialHeaderProps {
  isOpen: boolean;
  onClose: () => void;
}

const ASide: React.FC<MaterialHeaderProps> = ({ isOpen, onClose }) => {
  useIntervalEventsCountRequests();

  const classes = useStyles();
  return (
    <Drawer
      variant='permanent'
      className={cn(classes.drawer, {
        [classes.drawerOpen]: isOpen,
        [classes.drawerClose]: !isOpen,
      })}
      classes={{
        paper: cn({
          [classes.drawerOpen]: isOpen,
          [classes.drawerClose]: !isOpen,
        }),
      }}
      open={isOpen}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={onClose}>
          {isOpen ? <ChevronLeft /> : <ChevronRight />}
        </IconButton>
      </div>
      <Divider />
      <AuthWrapper>
        <ASideMenu isOpen={isOpen} isDebuggingEnabled={false}/>
      </AuthWrapper>
    </Drawer>
  );
};

export default ASide;
