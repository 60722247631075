import { MuiThemeProvider } from '@material-ui/core/styles';
import { useUserTheme } from 'modules/common/hooks/useUserTheme';
import { useAuth } from 'modules/common/selectors/auth';
import * as React from 'react';
import Layout from '../components/Layout/Layout';
import createRootStore from '../createRootStore';

export const store = createRootStore();

const AppContainer: React.SFC = ({ children }) => {
	const { user } = useAuth();
	const { userTheme } = useUserTheme(user && user.username);

	return (
		<MuiThemeProvider theme={userTheme.theme}>
			<Layout>{children}</Layout>
		</MuiThemeProvider>
	);
};

export default AppContainer;
