
import config from 'api/config';
import axios, { AxiosRequestConfig } from 'axios';
axios.defaults.withCredentials = true;


const AXIOS_DEFAULT_SETTINGS: AxiosRequestConfig = {
  baseURL: config.URL,
  headers: {
    'Content-Type': 'application/json',
    // 'Access-Control-Allow-Credentials': true
  },
  // credentials: 'same-origin',
  // redirect: 'follow', // manual, *follow, error
  // referrer: 'no-referrer', // no-referrer, *client
  timeout: 600000,
  responseType: 'json',
  withCredentials: true
};


/**
 * Runtime AXIOS instance, which includes whole config
 */

export const API = axios.create(AXIOS_DEFAULT_SETTINGS);