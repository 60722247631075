import * as React from "react";
import DebugDialog from "modules/common/debug/components/DebugDialog";
// const styles = require("./DebugContainer.scss");

export interface DebugContainerProps {
    open: boolean;
    onClose: () => void;
}

const DebugContainer: React.SFC<DebugContainerProps> = ({open, onClose}) => {
    return (
        <DebugDialog open={open} onClose={onClose}/>
    );
};

export default DebugContainer;