import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import styles from './Loader.module.scss';

interface LoaderProps {
    color?: 'primary' | 'secondary'
    label?: string;
}

const Loader: React.SFC<LoaderProps> = ({ color = 'primary', label }) => {
    return (
        <div className={styles.preloader}>
            <CircularProgress disableShrink variant="indeterminate" color={color}/>
            {label && <label>{label}</label>}
        </div>
    )
}

export default Loader;