export enum Modules {
    'NONE',
    'START',
    'VIEWS',
    'DATA',
    'CONSUMPTION',
    'DEVICES',
    'DEVICE',
    'EVENTS',
    'EVENTSV2',
    'REPORTS',
    'SCHEDULER',
    'USERS',
    'SETTINGS',
    'HELP',
    'MAIL',
    'BUY',
    'HMI',
    'BUILDING',
    'BUILDINGS',
    'VIEW_EDITOR',
    'MAP'
};