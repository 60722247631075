
import React from "react";
import { useAuth } from "modules/common/selectors/auth";

interface AuthWrapperProps {

}


//
// Authorization module
//
const AuthWrapper: React.SFC<AuthWrapperProps> = ({ children }) => {
    const {user} = useAuth();

    const isAuthenticated = !!user;

    return <>{isAuthenticated && children}</>
};

export default AuthWrapper;