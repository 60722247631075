import { customViewData } from 'mocks/view';
import { AnyAction, Dispatch } from 'redux';
import { ReduxAction } from '../../common/helpers/redux/actions';
import { AsyncReducerState, defaultAsyncReducerState } from '../../common/helpers/redux/reducers';
import { useDispatchOnce } from '../../common/helpers/redux/useActions';
import useSelector from '../../common/helpers/redux/useSelector';
import { ViewsRootState } from './index';
import { delay, viewsActions } from './views';
import { uniqueArray } from 'helpers/data';

const VIEW_MODULE = 'view/view/';
const VIEW_EDIT_MODULE = 'view/edit';


export interface ViewResponse {
    view: ViewType;
}

const REQUEST = VIEW_MODULE + '_REQUEST';
const RESPONSE_SUCCESS = VIEW_MODULE + '_RESPONSE_SUCESS';
const RESPONSE_FAILURE = VIEW_MODULE + '_RESPONSE_FAILURE';
const FETCH = VIEW_MODULE + '_FETCH';
const RESET = VIEW_MODULE + '_RESET';

const EDIT_REQUEST = VIEW_EDIT_MODULE + '_REQUEST';
const EDIT_RESPONSE_SUCCESS = VIEW_EDIT_MODULE + '_RESPONSE_SUCESS';
const EDIT_RESPONSE_FAILURE = VIEW_EDIT_MODULE + '_RESPONSE_FAILURE';
const EDIT_FETCH = VIEW_EDIT_MODULE + '_FETCH';


export interface ViewState extends AsyncReducerState {
    view: ViewType | null;
}

const initialState: ViewState = {
    view: null,
    ...defaultAsyncReducerState
};

const viewReducer = (state = initialState, action: AnyAction): ViewState => {
    switch (action.type) {
        case REQUEST:
        case EDIT_REQUEST:
            return { ...state, fetching: true, fetched: false };
        case RESPONSE_SUCCESS:
        case EDIT_RESPONSE_SUCCESS:
            return { ...state, fetching: false, };
        case RESPONSE_FAILURE:
        case EDIT_RESPONSE_FAILURE:
            return { ...state, fetching: false, fetched: false, error: true };
        case FETCH:
            return { ...state, fetched: true, view: action.payload.view };
        // case EDIT_FETCH:
        //     return { ...state, fetched: true, view: {...state.view, ...action.payload.view}};
        case RESET:
            return initialState; 

        default:
            return state;
    }
};

export default viewReducer;

let TEMPORARY_VIEW_RESPONSE: ViewResponse = {
    view: customViewData
};

const fetchx = (some: string): Promise<ViewResponse> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(TEMPORARY_VIEW_RESPONSE), 1);
    });
};

// @ACTIONS
const actions = {
    get: {
        request: (id: string) => async (dispatch: Dispatch<AnyAction>) => {
            try {
                dispatch({ type: REQUEST });
                const response = await fetchx('some');  // ??
                dispatch(actions.get.success());
                dispatch(actions.get.fetch(response));
            } catch (err) {
                dispatch(actions.get.failure(err));
            }
        },
        fetch: (response: ViewResponse): ReduxAction<ViewResponse> => {
            return {
                type: FETCH,
                payload: response
            };
        },
        success: (): AnyAction => ({
            type: RESPONSE_SUCCESS
        }),
        failure: (error: string): AnyAction => ({
            type: RESPONSE_FAILURE,
            error: true
        })
    },
    edit: {
        request: (uuid: string, view: ViewType) => async (dispatch: Dispatch<any>) => {
            try {
                dispatch({ type: EDIT_REQUEST });
                await delay(500);   // ??
                dispatch(actions.edit.success());
                dispatch(actions.edit.fetch({view}));
            } catch (err) {
                dispatch(actions.edit.failure(err));
            }
        },
        fetch: (response: ViewResponse) => async (dispatch: Dispatch<AnyAction>) => {
            dispatch({ type: EDIT_FETCH, payload: response });
            dispatch(viewsActions.update(response.view));
        },
        success: (): AnyAction => ({
            type: EDIT_RESPONSE_SUCCESS
        }),
        failure: (error: string): AnyAction => ({
            type: EDIT_RESPONSE_FAILURE,
            error: true
        }),
    },
    reset: () => ({
        type: RESET
    })
};

// HOOKS

const selectView = (state: ViewsRootState): ViewState => state.view;

export const useViewState = (): ViewState => {
    return useSelector<ViewsRootState, ViewState>(selectView, []);
};

export const useView = (uuid: string): ViewType | null => {
    const { view, fetched } = useViewState();
    const shouldLoad = () => Boolean(!fetched || (view && view.uuid !== uuid));
    useDispatchOnce(actions.get.request(uuid), [uuid], shouldLoad);
    return view;
};

export const useViewRelatedPoints = (uuid: string): string[] => {
    const view: ViewType | null = useView(uuid);
    let points: string[] = [];

    if (view) {
        if (view.type === 'custom') {
            const customView = view as CustomView;
            const config = customView.config;
            if (config && config.widgets) {
                points = config.widgets.reduce(
                    (points: string[], nextWidget: Widget) => {
                        if (nextWidget.config && nextWidget.config.points) {
                            return [...points, ...nextWidget.config.points];
                        } else {
                            return points;
                        }
                    }, []);
            }
        } else if (view.type === 'draw') {
            //
        }
    }

    // Make array unique
    return uniqueArray(points);
}


// Exports
export const viewActions = actions;