import { APP_NAME, APP_VERSION } from 'config/version';
import React from 'react';
import ReactDOM from 'react-dom';
import { API } from './api/axios';
import initAxiosInterceptors from './api/axiosInterceptors';
import App from './App';
import './i18n/i18n';
import * as serviceWorker from './serviceWorker';
import './setupTests';
import version from './version.json';

type PROCESS_ENV = 'development' | 'test' | 'production';
const LOCALHOST_INCORRECT_HOST = `localhost`;
const LOCALHOST_HOST = `127.0.0.1`;

// Initializations
initAxiosInterceptors(API);
serviceWorker.unregister();

(window as any).__VERSION = version;

/**
 * Redirect from localhost to 127.0.0.1 to resolve CORS connection issues in local development
 */
function corsRedirection(): void {
    const port = window.location.port;
    if (window.location.host.includes(LOCALHOST_INCORRECT_HOST)) {
        window.location.host = `${LOCALHOST_HOST}:${port}`;
    }
}

function getWindowTitle(env: PROCESS_ENV): string {
    const PRODUCTION_TITLE = `${APP_NAME}`;
    const DEV_TITLE = `[[DEV][${PRODUCTION_TITLE}]]`
    const isLocalhost = window.location.host.includes(LOCALHOST_HOST);

    if (env === 'development' && isLocalhost) {
        return DEV_TITLE;
    } else {
        return PRODUCTION_TITLE;
    } 
}

function registerEnvironment(env: PROCESS_ENV): void {
    corsRedirection();
    const title = getWindowTitle(env);
    document.title = title;
}

registerEnvironment(process.env.NODE_ENV);

ReactDOM.render(<App />, document.getElementById('root'));
