import { AnyAction, Dispatch } from 'redux';
import { ReduxAction } from '../../common/helpers/redux/actions';
import { AsyncReducerState, defaultAsyncReducerState } from '../../common/helpers/redux/reducers';
import { useDispatchOnce } from '../../common/helpers/redux/useActions';
import useSelector from '../../common/helpers/redux/useSelector';
import { ViewsRootState } from './index';
import { viewsData } from 'mocks/views';

const VIEWS_MODULE = 'view/views/';
const REMOVE_MODULE = 'view/views/delete/';


export interface ViewsResponse {
    views: ViewType[];
}

const REQUEST = VIEWS_MODULE + 'REQUEST';
const RESPONSE_SUCCESS = VIEWS_MODULE + 'RESPONSE_SUCESS';
const RESPONSE_FAILURE = VIEWS_MODULE + 'RESPONSE_FAILURE';
const FETCH = VIEWS_MODULE + 'FETCH';
const UPDATE = VIEWS_MODULE + 'UPDATE';

const REMOVE_REQUEST = REMOVE_MODULE + 'REMOVE_REQUEST';
const REMOVE_RESPONSE_SUCCESS = REMOVE_MODULE + 'REMOVE_RESPONSE_SUCESS';
const REMOVE_RESPONSE_FAILURE = REMOVE_MODULE + 'REMOVE_RESPONSE_FAILURE';
const REMOVE_FETCH = REMOVE_MODULE + 'REMOVE_FETCH';

export interface ViewsState extends AsyncReducerState {
    views: ViewType[];
}

const initialState: ViewsState = {
    views: [],
    ...defaultAsyncReducerState
};

const viewsReducer = (state = initialState, action: AnyAction): ViewsState => {
    // For now, don't handle any actions
    // and just return the state given to us.\

    switch (action.type) {
        case REQUEST:
            return { ...state, fetching: true, fetched: false };
        case RESPONSE_SUCCESS:
            return { ...state, fetching: false, };
        case RESPONSE_FAILURE:
            return { ...state, fetching: false, fetched: false, error: true };
        case FETCH:
            return { ...state, fetched: true, views: action.payload.views };

        case REMOVE_REQUEST:
            return state;
        case REMOVE_RESPONSE_SUCCESS:
            return state;
        case REMOVE_RESPONSE_FAILURE:
            return state;
        case REMOVE_FETCH:
            return { ...state, views: state.views.filter(view => view.uuid !== action.payload.uuid ) };    
        case UPDATE:
                return {...state, views: state.views.map(view => {
                    if(view.uuid === action.payload.view.uuid){
                        return action.payload.view;
                    } else {
                        return view;
                    }
                })}

        default:
            return state;
    }
};

export default viewsReducer;

const TEMPORARY_VIEWS_RESPONSE: ViewsResponse = {
    views: viewsData
};

const fetchx = (some: string): Promise<ViewsResponse> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(TEMPORARY_VIEWS_RESPONSE), 1);
    });
};
export const delay = (ms: number): Promise<void> => {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(), ms);
    });
};

// @ACTIONS
const actions = {
    get: {
        request: () => async (dispatch: Dispatch<AnyAction>) => {
            try {
                dispatch({ type: REQUEST });
                const response = await fetchx('some');
                // const data: Initialize = await response.json();
                dispatch(actions.get.fetch(response));
            } catch (err) {
                dispatch(actions.get.failure(err));
            }
        },
        fetch: (response: ViewsResponse): ReduxAction<ViewsResponse> => {
            return {
                type: FETCH,
                payload: response
            };
        },
        success: (): AnyAction => ({
            type: RESPONSE_SUCCESS
        }),
        failure: (error: string): AnyAction => ({
            type: RESPONSE_FAILURE, error: true
        }),
    },
    update: (view: ViewType) => ({
        type: UPDATE,
        payload: {
            view
        }
    }),

    remove: {
        request: (uuid: string) => async (dispatch: Dispatch<AnyAction>) => {
            try {
                dispatch({ type: REQUEST });
                await delay(200);
                // const data: Initialize = await response.json();
                dispatch(actions.remove.fetch(uuid));
            } catch (err) {
                
            }
        },
        fetch: (uuid: string) => {
            return {
                type: REMOVE_FETCH,
                payload: { uuid }
            }
        }
    }
};


// HOOKS
const selectViews = (state: ViewsRootState): ViewsState => state.views;

export const useViews = (): ViewsState => {    
    return useSelector<ViewsRootState, ViewsState>(selectViews);
};

export const useViewsList = (force: boolean = false): ViewType[] => {
    const viewsState = useViews();
    const { views, fetched } = viewsState;
    const shouldLoad = () => !fetched || force;
    useDispatchOnce(actions.get.request(), [], shouldLoad);
    return views;
};

// export const useViewRemove = () => {
//     const dispatch = useDispatch();
//     const remove = (uuid: string) => useCallback(() => dispatch(actions.remove.request(uuid)), []);
//     return remove;
// }

export const viewsActions = actions;