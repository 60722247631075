import * as React from "react";
import { SnackbarProvider, useSnackbar } from 'notistack';
import { useUINotifications } from "modules/common/redux/uiNotifications";
import { useEffect } from "react";
// const styles = require("./UINotifications.scss");

interface UINotificationsProps {
    notifications: UINotification[];
}

const UINotifications: React.SFC<UINotificationsProps> = ({notifications}) => {
    const { enqueueSnackbar } = useSnackbar();


    useEffect(() => {
        notifications.forEach(
            notification => enqueueSnackbar(notification.message, {variant: notification.variant || 'info'})
        )
    }, [notifications]);


    return null;
};

export default UINotifications; 