import UINotifications from "modules/common/components/UINotifications/UINotifications";
import { useUINotifications } from "modules/common/redux/uiNotifications";
import * as React from "react";
import { SnackbarProvider } from "notistack";
// const styles = require("./UINotificationsContainer.scss");

interface UINotificationsContainerProps { }

const UINotificationsContainer: React.SFC<UINotificationsContainerProps> = ({ }) => {
    const { data: { notifications } } = useUINotifications();

    return (
        <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
            <UINotifications notifications={notifications} />
        </SnackbarProvider>
    );
};

export default UINotificationsContainer; 