import { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';
import { store } from 'modules/common/containers/AppContainer';
import { appActions } from 'modules/common/redux/app';
import { API } from 'api/axios';
import { authAPI } from 'api/endpoints';
import { getSessionStorageUser, clearSessionStorageUser } from 'modules/common/auth/storage';
import axios from 'axios';


interface AxiosRequestConfigWithRetry extends AxiosRequestConfig {
    _retry?: boolean;
}

function logout() {

}

/**
 * Token exchange and refresh middleware,
 * in axios is called interceptor
 */
export default function init(axiosInstance: AxiosInstance) {
    let isRefreshing = false;
    let failedQueue: any[] = [];

    const processQueue = (error: any, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        })
        failedQueue = [];
    }


    /**
     * Middleware (in axios called interceptor) for correct response handler.
     */
    function correctResponseHandler(response: AxiosResponse) {
        store.dispatch(appActions.processing(false));
        return response;
    }

    /** 
     * 
     * Main refresh token middleware
     * 
     */
    function incorrectResponseHandler(error: AxiosError) {
        /**
         * We are getting response,
         * first of all we need to reset processing flag
         */
        store.dispatch(appActions.processing(false));
        const originalRequestError: AxiosRequestConfigWithRetry = error.config;

        const isNotAuthorizedResponse = error.response && error.response.status === 401;
        const isRetryResponse = Boolean(originalRequestError._retry);
        const isFailedLoginResponse: boolean = Boolean(originalRequestError.url && originalRequestError.url.includes('auth/login'));
        const invalidApiMethodResponse = error.response && error.response.status === 404;
        const isTokenRequestResponse: boolean = Boolean(originalRequestError.url && originalRequestError.url.includes('auth/token'));
        const isFailedTokenRequest = isTokenRequestResponse && Boolean(error.response);

        /**
         * Reject in case of:
         *  - it's failed token request
         *  - it's failed login request
         *  - it's invalid api method
         */
        if (isFailedTokenRequest || isFailedLoginResponse || invalidApiMethodResponse || (isRetryResponse && isNotAuthorizedResponse)) {
            return Promise.reject(error);
        }

        /**
         * It was not retry response
         */
        if (!isRetryResponse) {
            // ??
            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({ resolve, reject })
                }).then(token => {
                    return axios(originalRequestError);
                }).catch(err => {
                    return err;
                })
            }
            originalRequestError._retry = true;
            isRefreshing = true;
            return new Promise(async function (resolve, reject) {
                try {
                    const response = await API.post(authAPI.refreshToken(), { username: getSessionStorageUser() });
                    processQueue(null, response.data.token);
                    resolve(axios(originalRequestError));
                } catch (err) {
                    clearSessionStorageUser();
                    if (!isFailedLoginResponse && !window.location.href.includes('login')) {
                        window.location.href = '/login';
                    }
                    processQueue(err, null);
                    reject(err);
                } finally {
                    isRefreshing = false;
                }
            })
        } else {
            //
        }

        return Promise.reject(error);
    }

    /**
     * There is some requests where we don't need to include to store updates.
     * It's mainly interval requests like notifications or poll.
     * 
     */
    function isRequestRequireProcessingIndicator(url: string | undefined = ''): boolean {
        let included = false;

        const PATH_FORBIDDEN_WORDS = [
            'poll',
            'notifications',
            'token',
            'registers',
            'summary'
        ];

        if (url) {
            included = !PATH_FORBIDDEN_WORDS.some(part => url.includes(part));
        }

        return included;
    }


    /**
     * Update request processing for required modules
     * @param request 
     */
    function includeRequestProcessingInterceptor(request: AxiosRequestConfig) {
        const included = isRequestRequireProcessingIndicator(request.url);

        if (included) {
            store.dispatch(appActions.processing(true));
        }

        return request;
    }

    /**
     * Bind request incerceptors to axios instance
     */
    API.interceptors.request.use(
        includeRequestProcessingInterceptor
    );

    /**
     * Bind response incerceptors to axios instance
     */
    API.interceptors.response.use(
        correctResponseHandler,
        incorrectResponseHandler
    );
}