import { IModule } from 'redux-dynamic-modules';
import { pointsReducer, PointsState } from '../../common/redux/points';
import pollReducer, { PollState } from '../../common/redux/poll';
import viewReducer, { ViewState } from './view';
import viewsReducer, { ViewsState } from './views';

export const viewsReducers: ViewsRootState = {
    views: (viewsReducer as any),
    view: (viewReducer as any),
    points: (pointsReducer as any),
    poll: (pollReducer as any)
};

export interface ViewsRootState {
    views: ViewsState;
    view: ViewState;
    points: PointsState;
    poll: PollState;
}

export const getViewsModule = (): IModule<ViewsRootState> => ({
        id: "views",
        reducerMap: {
            views: viewsReducer,
            view: viewReducer,
            points: pointsReducer,
            poll: pollReducer
        },
        initialActions: [
            {type: 'INIT_VIEW_MODULE'}
        ]
});