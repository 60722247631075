import Grow from "@material-ui/core/Grow";
import Tooltip from "@material-ui/core/Tooltip";
import React from "react";


interface TooltipWrapperProps {
    title: string;
    active: boolean;
    index: number;
  }
  
const TooltipWrapper: React.FC<TooltipWrapperProps> = ({ title, active, children, index }) => (
    <>
      {
        active
          ? children
          : <Grow in={true} timeout={500 + (index * 50)}>
            <Tooltip title={title} placement='right' style={{ maxWidth: 40 }}>
              <span>
                {children as any}
              </span>
            </Tooltip>
          </Grow>
      }
    </>
  )

  export default TooltipWrapper;