import { Theme } from '@material-ui/core';
import { theme, themeDTS, themeELP } from 'modules/common/theme/materialTheme';
import { useEffect, useMemo } from 'react';
import styles from '../../../styles/colors.module.scss'
import BPLogo from '../../../images/BPLogo.png';
import ELPLogo from '../../../images/ELPLogo.png';
import DTSLogo from '../../../images/DTSLogo.png';

const INITIAL_USER_THEME_VALUE = {
  theme: theme,
  logo: BPLogo,
  primary: styles.primaryColor,
  secondary: styles.secondaryColor,
  primaryLighten: styles.primaryLightenColor,
  secondaryLighten: styles.secondaryLightenColor,
  primaryDarken: styles.primaryDarkenColor,
  secondaryDarken: styles.secondaryDarkenColor,
}

type ThemeDomainColors = {
  theme: Theme;
  logo: string;
  primary: string;
  secondary: string;
  primaryLighten: string;
  secondaryLighten: string;
  primaryDarken: string;
  secondaryDarken: string;
}

type ThemeDomainColor = {
  [key: string]: ThemeDomainColors;
}

const themeDomainColor: ThemeDomainColor = {
  'demo@dtsserwis.pl': {
    theme: themeDTS,
    logo: DTSLogo,
    primary: styles.primaryColorDTS,
    secondary: styles.secondaryColorDTS,
    primaryLighten: styles.primaryLightenColorDTS,
    secondaryLighten: styles.secondaryLightenColorDTS,
    primaryDarken: styles.primaryDarkenColorDTS,
    secondaryDarken: styles.secondaryDarkenColorDTS,
  },
  'demo@el-piast.com': {
    theme: themeELP,
    logo: ELPLogo,
    primary: styles.primaryColorELP,
    secondary: styles.secondaryColorELP,
    primaryLighten: styles.primaryLightenColorELP,
    secondaryLighten: styles.secondaryLightenColorELP,
    primaryDarken: styles.primaryDarkenColorELP,
    secondaryDarken: styles.secondaryDarkenColorELP,
  }
}

export const useUserTheme = (username: string | null) => {
  const { userTheme } = useMemo(() => {
    let userTheme = INITIAL_USER_THEME_VALUE;
    if (username) {
      if (themeDomainColor[username]) {
        userTheme = themeDomainColor[username];
      }
    }
    return {
      userTheme
    }
  }, [username])

  useEffect(() => {
    document.documentElement.style.setProperty('--primary-color', userTheme.primary)
    document.documentElement.style.setProperty('--secondary-color', userTheme.secondary)
    document.documentElement.style.setProperty('--primary-lighten-color', userTheme.primaryLighten)
    document.documentElement.style.setProperty('--secondary-lighten-color', userTheme.secondaryLighten)
    document.documentElement.style.setProperty('--primary-darken-color', userTheme.primaryDarken)
    document.documentElement.style.setProperty('--secondary-darken-color', userTheme.secondaryDarken)
  }, [userTheme])

  return {
    userTheme
  }
}