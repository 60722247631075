import queryString from 'query-string';
import { createUrl } from './helpers';

/**
 * D - doc
 * A - api
 * T - tested
 */

export const authAPI = {
	// D/A/T
	login: () => createUrl('/auth/login'),
	// D/A/T
	verify: () => createUrl('/auth/verify'),
	// D/A/T
	logout: () => createUrl('/auth/logout'),
	// D/A/T
	refreshToken: () => createUrl('/auth/token'),
};

export const buildingsAPI = {
	// D/A/T - brakuje count/countAll i wyszukiwania
	getBuildings: (settings: any) => createUrl(`/buildings/?${queryString.stringify(settings)}`),
	// D/A/T
	getBuilding: (uuid: string) => createUrl(`/building/${uuid}`),
	// D/A/T
	addBuilding: () => createUrl(`/building/`),
	// D/A/T
	updateBuilding: (uuid: string) => createUrl(`/building/${uuid}`),
	// D/A/T
	removeBuilding: (uuid: string) => createUrl(`/building/${uuid}`),
	// D
	getBuildingPoints: (uuid: string) => createUrl(`/building/${uuid}/points`),
	// D
	selectBuildingsList: (q: string) => createUrl(`/buildings/list?${queryString.stringify({ q })}`),
};

export const buildingGroupsAPI = {
	getBuildingGroups: (uuid: string) => createUrl(`/groups/${uuid}`),
	addBuildingGroup: (uuid: string) => createUrl(`/groups/${uuid}`),
	updateBuildingGroup: (uuid: string, groupXid: string) => createUrl(`/groups/${uuid}/${groupXid}`),
	removeBuildingGroup: (uuid: string, xid: string) => createUrl(`/groups/${uuid}/${xid}`),
};

export const buildingEventsAPI = {
	getBuildingEvents: (buildingUUID: string) => createUrl(`/events/building/${buildingUUID}`),
};

export const pollAPI = {
	// D
	getPoll: (points: string[] = [], ts: number, buildingUUID?: string) =>
		createUrl(`/poll?${queryString.stringify(buildingUUID ? { b: buildingUUID, ts } : { p: points, ts })}`),
	// D
	setpoint: () => createUrl(`/poll/setpoint`),
};

export const devicesAPI = {
	getDevice: (uuid: string) => createUrl(`/device/${uuid}`),
	getDevices: (settings: any) => createUrl(`/devices?${queryString.stringify(settings)}`),
	addDevice: () => createUrl(`/device`),
	updateDevice: (uuid: string) => createUrl(`/device/${uuid}`),
	removeDevice: (uuid: string) => createUrl(`/device/${uuid}`),
	testDevice: (code: string) => createUrl(`/device/test/${code}`),
	reloadDevice: (uuid: string) => createUrl(`/device/${uuid}/reload`),

	// Registers/points
	getPoints: (points: string[], buildingUUID?: string, deviceUUID?: string) =>
		createUrl(`/points/?${queryString.stringify(buildingUUID ? { b: buildingUUID } : deviceUUID ? { d: deviceUUID } : { p: points })}`),
	getDeviceRegisters: (uuid: string) => createUrl(`/device/${uuid}/registers`),
	addDevicePoints: (uuid: string) => createUrl(`/device/${uuid}/points`),
	removePoint: (pointUUID: string) => createUrl(`/point/${pointUUID}`),
	updatePoint: (pointUUID: string) => createUrl(`/point/${pointUUID}`),
	connectVirtualHMI: () => createUrl(`/vhmi/connect`),
};

export const viewsAPI = {
	//
	getView: (uuid: string) => createUrl(`/view/${uuid}`),
	//
	getViews: () => createUrl(`/views/`),
	//
	getBuildingViews: (uuid: string) => createUrl(`/building/${uuid}/views`),
	//
	addView: (buildingUUID: string) => createUrl(`/view/${buildingUUID}`),
	//
	removeView: (uuid: string) => createUrl(`/view/${uuid}`),
	//
	updateView: (uuid: string) => createUrl(`/view/${uuid}`),
	//
	selectBuildingPoints: (buildingUUID: string, q: string) => createUrl(`/view/${buildingUUID}/points?${queryString.stringify({ q })}`),
};

export const eventsApi = {
	//
	getEvents: (options: any) => createUrl(`/events?${queryString.stringify(options)}`),
	//
	confirmEvents: (eventsUUIDS: string[]) => createUrl(`/events/confirm?${queryString.stringify({ e: eventsUUIDS })}`),
	//
	confirmEvent: (uuid: string) => createUrl(`/events/confirm/${uuid}`),
	//
	getPointEvents: (uuid: string) => createUrl(`/events/point/${uuid}`),
	//
	getBuildingEvents: (uuid: string) => createUrl(`/events/building/${uuid}`),
	//
	getDeviceEvents: (uuid: string) => createUrl(`/events/device/${uuid}`),
	//
	getEventsCount: () => createUrl(`/events/summary`),
};

export const eventsV2Api = {
	//
	getEvents: (options: any) => createUrl(`/events?${queryString.stringify(options)}`),
	//
	confirmEvents: (eventsUUIDS: string[]) => createUrl(`/events/confirm?${queryString.stringify({ e: eventsUUIDS })}`),
	//
	getPointEvents: (uuid: string) => createUrl(`/events/point/${uuid}`),
	//
	getBuildingEvents: (uuid: string) => createUrl(`/events/building/${uuid}`),
	//
	getDeviceEvents: (uuid: string) => createUrl(`/events/device/${uuid}`),
	//
	getEventsCount: () => createUrl(`/events/summary`),
	//
	getAlarmsBlocks: (options: any) => createUrl(`/alarms/blocks?${queryString.stringify(options)}`),
	//
	confirmAlarmBlock: () => createUrl(`/alarms/blocks`),
	//
	updateAlarmBlock: () => createUrl(`/alarms/blocks`),
	//
	deleteAlarmBlock: (deviceUUID: string, code: string) => createUrl(`/alarms/blocks/${deviceUUID}/${code}`),
};

export const notificationsApi = {
	//
	getActiveNotificationsCount: () => createUrl(`/notifications/count`),
	//
	getActiveNotifications: () => createUrl(`/notifications`),
	//
	makeNoficationsRead: (notificationsUUIDs: string[]) => createUrl(`/notifications/read?${queryString.stringify({ n: notificationsUUIDs })}`),
};

export const userApi = {
	changePassword: () => createUrl(`/user/password`),
};

/**
 *
 * PUT: /user/:uuid/password
 * {
 *   body: {
 *      current:
 *      new:
 *   }
 * }
 *
 */
